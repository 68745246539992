<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Trademark policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Trademark policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
          <p class="margin-0 desc">
            At UNI, we expect our users to respect the intellectual property rights of others. All
            capitalized terms used in the UNI Trademark Policy have the same meaning as defined in
            the UNI Terms of Service, which incorporates this policy by reference into its terms.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Discouragement of trademark infringement of UNI</p>
          <p class="margin-0 desc">
            Trademarks should not be used in any way that causes confusion about the source of goods
            or sponsorship. If you infringe the Trademarks of others, Your Content may be removed or
            suspended in whole or part. It is our policy, in appropriate circumstances and at our
            discretion, to disable and/or terminate accounts of users who repeatedly infringe or are
            charged repeatedly with infringing the Trademarks or other intellectual property rights
            of others.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="2">2. Recourse for trademark owners</p>
          <p class="margin-0 desc">
            If you are Trademark owner who believes your Trademark has been infringed on UNI, we
            provide a mechanism for you to notify us of your concerns. To help us respond
            appropriately, we ask you to provide us the following information:
          </p>
          <ol class="margin-0" type="number">
            <li class="margin-bottom-4 desc">
              Your full name and contact information, including address, phone number, and email
              contact
            </li>
            <li class="margin-bottom-4 desc">
              Identification of the Trademark and evidence of registration rights.
            </li>
            <li class="margin-bottom-4 desc">
              Your relationship to the owner of the Trademark rights.
            </li>
            <li class="margin-bottom-4 desc">
              Reasonably identification for us (e.g., URL link) of the location on UNI where you
              believe infringement has occurred
            </li>
            <li class="margin-bottom-4 desc">
              A short description of why you believe the use in question infringes your Trademark
              rights.
            </li>
            <li class="margin-bottom-4 desc">
              Verification that all the information in your report of infringement is accurate; that
              you have a good faith belief that the use of the intellectual property, described in
              the manner you have complained about, is not authorized by the rights owners, its
              agent, or the law; and that you are the owner, or authorized to act on behalf of the
              owner, of an exclusive intellectual property that allegedly is infringed.
            </li>
          </ol>
          <p class="margin-0 desc">
            For your convenience, we provide you with this web form to complete if you wish to
            report Trademark infringement.
          </p>
          <p class="margin-0 desc font-bolder">
            For the fastest handling, use our
            <a href="https://uni.xyz/contact" class="no-decor">web form</a>. If you use an
            alternative method, it may delay us in processing your request.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
