<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Copyright policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Copyright policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
          <p class="margin-0 desc">
            At UNI, we expect our users to respect the intellectual property rights of others. All
            capitalized terms used in the UNI Copyright Policy have the same meaning as defined in
            the UNI Terms of Service, which incorporates this policy by reference into its terms.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Discouragement of copyright infringement</p>
          <p class="margin-0 desc">
            We ask users of UNI to take care to avoid infringing the copyrights of others. If you
            infringe the copyrights of others, Your Content may be removed or suspended in whole or
            part. It is our policy, in appropriate circumstances and at our discretion, to disable
            and/or terminate accounts of users who repeatedly infringe or are charged repeatedly
            with infringing the copyrights or other intellectual property rights of others.
          </p>
          <p class="margin-0 desc">
            UNI respects the intellectual property rights of others and desires to offer a place
            which contains no content that violates those rights. Our Terms of Services require that
            information posted by Users be accurate, lawful, and not in violation of the rights of
            third parties. To promote these objectives, UNI provides a process for submission of
            complaints concerning content posted by our Users. Our policy and procedures are
            described and/or referenced in the sections that follow.
          </p>
          <p class="margin-0 desc">
            Please note that whether we disable access to or remove content, UNI may make a good
            faith attempt to forward the written notification, including the complainant’s contact
            information, to the User who posted the content and/or takes other reasonable steps to
            notify the Member that UNI has received notice of an alleged violation of intellectual
            property rights or other content violation. It is also our policy, in appropriate
            circumstances and in our discretion, to disable and/or terminate the accounts of Users,
            or groups as the case may be, who infringe or repeatedly infringe the rights of others
            or otherwise post unlawful content.
          </p>
          <p class="margin-0 desc">
            Please note that any notice or counter-notice you submit must be truthful and must be
            submitted under penalty of perjury. A false notice or counter-notice may give rise to
            personal liability. You may therefore want to seek the advice of legal counsel before
            submitting a notice or a counter-notice.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="2">
            2. Recourse for copyright owners: Reporting complaints with a notice
          </p>
          <p class="margin-0 desc">
            We also provide a mechanism for copyright owners, or those authorized to act on behalf
            of an owner of a copyright, to report infringing use of their content on UNI. In
            accordance with the Copyright office of the Government of India, we will respond
            expeditiously to claims of copyright infringement that are reported to our Copyright
            Agent. We provide you with the requirements of a proper complaint below. For your
            convenience, the entire text of the Copyright act GOI can be found online at:
            http://copyright.gov.in/
          </p>
          <p class="margin-0 desc">
            In order to provide us with a complaint notice that complies with the Copyright policy
            of GOI’s requirements, you should:
          </p>
          <ol class="margin-0" type="number">
            <li class="margin-bottom-4 desc">
              Provide your contact information, including your mailing address, telephone number,
              and, if available, an email address.
            </li>
            <li class="margin-bottom-4 desc">
              Identify the copyrighted work that you claim has been infringed. If multiple
              copyrighted works are covered in your Notice, you may provide a representative list of
              the copyrighted works that you claim have been infringed.
            </li>
            <li class="margin-bottom-4 desc">
              Provide information reasonably sufficient for us to locate each instance of allegedly
              infringing material that you are requesting to be removed or disabled. (For example,
              by providing each URL where the material may be found on UNI.)
            </li>
            <li class="margin-bottom-4 desc">
              Include the following statements in the body of the Notice:<br />
              I declare that I have a good faith belief that the disputed use of the copyrighted
              material or reference link to such material is not authorized by the copyright owner,
              its agent, or the law (e.g., as a fair use).
              <br /><br />I declare that the information in this Notice is accurate and, under
              penalty of perjury, that I am the owner or authorized to act on behalf of the owner of
              an exclusive copyright that is allegedly infringed.
            </li>
            <li class="margin-bottom-4 desc">
              Include a physical or electronic signature (e.g. typing your printed name)
            </li>
            <li class="margin-bottom-4 desc">
              Deliver this Notice, with all items completed, to the Designated Copyright Agent for
              UNI, at:<br /><br />Attn: Copyright Agent<br /><br />copyright@uni.xyz<br /><br />Or<br /><br />Attn:
              Copyright Agent<br />
              UNI.<br />305-A, Yamuna -7, D6<br />Vasant Kunj, New Delhi -110070
            </li>
          </ol>
          <p class="margin-0 desc">
            For your convenience, we provide you with this web form to complete and send via email
            if you wish to report copyright infringement.
          </p>
          <p class="margin-0 desc">
            For the fastest handling, use our web form. If you use an alternative method, it may
            delay us in processing your request.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="3">
            3. If there is a copyright complaint against you
          </p>
          <p class="margin-0 desc">
            If you receive a notice that a posting or portion of Your Content has been removed or
            suspended due to a copyright complaint, that means that we removed it at the request of
            a content owner. If you believe the material was removed in error, you have the option
            to file a counter-notification (the proper format for such counter-notice is defined
            below). When we receive a proper counter-notification, we will forward it to the party
            who originally complained about the alleged copyright violation. If we do not receive a
            notice from the original complainant within 10 business days, stating that the original
            complainant is seeking a court order to prevent further infringement of the content at
            issue, we will clear the complaint from your account’s record, and, at our discretion,
            we may replace the content that was removed.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="4">4. How to file a counter notification</p>
          <p class="margin-0 desc">
            Please note that there are potentially seriously consequences for fraudulent or bad
            faith submissions of such notifications or counter notifications. Such consequences may
            be imposed pursuant to the Copyright office GOI. Before submitting a
            counter-notification, you should be sure that you are the actual rights holder of the
            content removed or that you have a good faith belief that content was erroneously
            removed. It is important to make sure that you understand the repercussions of
            submitting a false counter notification.
          </p>
          <p class="margin-0 desc">
            A proper counter notification should be sent by replying to the email notice you
            received, or by sending it to UNI’s Copyright Agent as provided above, and it should
            contain the following:
          </p>
          <ol class="margin-0" type="number">
            <li class="margin-bottom-4 desc">Your name, address, and telephone number.</li>
            <li class="margin-bottom-4 desc">
              The URLs of the content that was removed or disabled by us (you can copy and paste the
              link provided in the notification email)
            </li>
            <li class="margin-bottom-4 desc">
              The following statements: <br /><br />I consent to the jurisdiction of NCT District
              Court for the judicial district in which my address is located, or if my address is
              outside India, for any judicial district in which UNI is located. I will accept
              service of process from the claimant of copyright infringement. <br /><br />I declare,
              under penalty of perjury, that I have a good faith belief that the content was removed
              as a result of a mistake or misidentification of the material to be removed or
              disabled.
            </li>
            <li class="margin-bottom-4 desc">
              Your physical or electronic signature (e.g., typing your full name)
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
