import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections: any = [
    {
      text: `Discouragement of copyright infringement`,
      id: `1`
    },
    {
      text: `Recourse for copyright owners: Reporting complaints with a notice`,
      id: `2`
    },
    {
      text: `If there is a copyright complaint against you`,
      id: `3`
    },
    {
      text: `How to file a counter notification`,
      id: `4`
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = `Copyright policy - UNI`;
    const description = `At UNI, we expect our users to respect the intellectual property rights of others. 
      All capitalized terms used in the UNI Copyright Policy have the same meaning as defined in the UNI 
      Terms of Service, which incorporates this policy by reference into its terms.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
